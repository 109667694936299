#progressbar {
  //  position: fixed;
  //  left: 0;
  //  top: calc(#{$navbar-height} + .5rem);
    z-index: 1030;
    min-width: 100%;
    height: 1px;
    appearance: none;
    border: none;
    background-color: transparent;
    color: $progressbar-color !important;
  }
  
  #progressbar::-webkit-progress-bar {
    background-color: transparent;
  }
  
  #progressbar::-webkit-progress-value {
    background-color: $progressbar-color;
  }
  
  #progressbar::-moz-progress-bar {
    background-color: $progressbar-color;
  }
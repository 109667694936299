// Variables
@import 'variables';
// Bootstrap and its default variables
@import 'bootstrap';
// Other imports
@import 'keyframes';
@import 'navbar';
@import 'logo';
@import 'progress';
@import 'lazyload';
@import 'shadows';

article p {
  // text-align: justify;
  // text-justify: auto;
  hyphens: auto;
}

// article figure {
//   margin-left: -10px;
//   margin-right: -10px;
// }
// @include media-breakpoint-up(md) {
//   article figure {
//     margin-left: -16.66%;
//     margin-right: -16.66%;
//   }
// }
// @include media-breakpoint-up(lg) {
//   article figure {
//     margin-left: -32%;
//     margin-right: -32%;
//   }
// }
$enable-shadows: true;

body {
  text-shadow: .5px 1px 1px rgba(0, 0, 0, .1);
  font-weight: 300;

  .text-white,
  .text-light {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
  }
}

a {
  transition: all .35s;

  &:hover,
  &:focus {
    transition: all .35s;
    text-decoration: none;
    text-shadow: 0 0 1em gray('200');
  }
}

.text-white a {
  color: white;
  text-decoration-color: theme-color('secondary');
  // text-decoration-style: double;
  // text-decoration-line: underline;
}

.text-black a {
  color: rgb(8, 8, 8);
  text-decoration-color: theme-color('secondary');
  // text-decoration-style: double;
  // text-decoration-line: underline;
}

h1,
h2,
h3,
h4 {
  font-weight: 250;
  a {
    color: theme-color('deep-blue')
  }
}

// article {
//   section#content{
//   p {
//     max-width: 720px;
//     text-align: justify;
//   }
// }
// }
.vh-100 {
  height: 100vh;
  height: var(--vh);
}

.fg-header {
  // height: 225px;
  // max-height: 25vh;
  padding-top: calc(#{$navbar-height} + 0.5rem);
  background-color: theme-color('deep-blue');
  // background-attachment: fixed;
}

.fade-in {
  opacity: 0;
  /* make things invisible upon start */
  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  animation: fadeIn ease-in 1;
  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

svg {
  .none {
    fill: none;
  }
}

::selection {
  text-shadow: none;
  color: $white;
  background: #222;
}

img {
  &::selection {
    color: $white;
    background: 0 0;
  }
}

#about {
  flex: 1;
}

.bg-hero {
  background-image: url(../img/modulos-principal@2x.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-hero2 {
  //background-image: url(../img/vista-paineis@2x.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-shadow: -1px -1px 0 theme-color('deep-blue'),
    1px -1px 0 theme-color('deep-blue'),
    -1px 1px 0 theme-color('deep-blue'),
    1px 1px 0 theme-color('deep-blue');
}

h1 {
  letter-spacing: 8px;
}

h5 {
  letter-spacing: 3px;
}

.bg-shade-primary {
  height: inherit;
  background-color: rgba(27, 74, 174, 0.5);
}

.bg-shade-primary2 {
  height: inherit;
  background-color: rgba(5, 46, 28, 0.3);
}

.bg-deep-blue {
  background-color: theme-color('deep-blue');
}

.bg-shade-gray {
  height: inherit;
  background-color: rgba(0, 0, 0, 0.5);
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: flex;
}

#carouselFotos {
  svg {
    background-color: $white;
  }
  
  @include media-breakpoint-up(md) {
    .carousel-control-prev {
      margin-left: -100px;
    }
    
    .carousel-control-next {
      margin-right: -100px;
    }
  }
}

.header-content-inner {
  hr {
    width: 5%;
    border-top: 3px solid theme-color('primary');
  }
}

form.form .form-control {
  margin-bottom: 0.5rem;
}

.navbar-toggler-icon {
  background: transparent;
}
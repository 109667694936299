%search-input-expanded {
  width: 90%;
  border: none;
  border-bottom: 1px solid theme-color('secondary');
  transition: all ease 500ms;
  line-height: 150%;
}

.navbar {
  background-color: theme-color('blue-sky');
  transition: all 500ms ease-in-out;
  //padding: 0 10px;
  line-height: 0;
  flex-wrap: wrap;

  .navbar-collapse {
    border: none;
  }

  .form-search {
    flex-wrap: nowrap;
  }

  .navbar-nav {
    .nav-link {
      color: $gray-200;

      &:focus {
        color: $gray-200;
      }

      &:hover,
      &:focus:hover {
        color: $white;
      }
    }
  }

  svg,
  #logo-brand {
    height: $navbar-height;
  }

  // .navbar-brand {
  //   padding: 0 !important;

  .all,
  .land,
  .ocean,
  .st0,
  .st1,
  #title {
    fill: $gray-200 !important;
  }

  form input,
  form button,
  .nav-link {
    color: $gray-200;

    &:focus {
      color: $gray-200;
    }

    &:hover,
    &:focus:hover {
      color: $white;
    }
  }
  

  #navbar-collapse.show {
    li {
      margin: 10px 0;
    }

    form input {
      @extend %search-input-expanded;
    }
  }

  .dropdown-item {
    font-weight: inherit;
  }

  form {

    &:hover input,
    button:hover+input {
      @extend %search-input-expanded;
    }

    input {

      &:focus,
      &:hover,
      &:active,
      &:valid {
        @extend %search-input-expanded;
      }

      width: 0;
      border: none;
      color: $gray-800;

      &::placeholder {
        color: $gray-400;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .navbar {
    .dropdown-menu {
      left: -2.25rem;
      box-shadow: $box-shadow;
    }

    &:not(.affix) {
      border-color: rgba(255, 255, 255, 0.3);
      background-color: transparent;

      .navbar-brand {
        color: rgba(255, 255, 255, 0.9);

        &:hover,
        &:focus {
          color: $white;
        }
      }

      
    }
  }

  // .dropdown:hover .dropdown-menu {
  //   display: block;
  // }
}
#logo-brand,
#logo-white-bg {
  .faded {
    fill: #AC313F;
  }
  .solid {
    fill: #333;
  }
  #title {
    fill: #7f1517;
  }
}

#logo-dark-bg {
  .faded {
    fill: rgb(49, 106, 172);
  }
  .solid {
    fill: #eee;
  }
  #title {
    fill: #f5f5f5;
  }
}